import { inject } from 'vue';
import * as VueRouter from "vue-router";

const UserLogin = () => import(/* webpackChunkName: "UserLogin" */ "../pages/auth/Login.vue");
const Dashboard = () => import(/* webpackChunkName: "Dashboard" */ "../components/dashboard/ReportingDashboard.vue");
const Patients = () => import(/* webpackChunkName: "Patients" */ "../components/patients/index.vue");
const PatientDetails = () => import(/* webpackChunkName: "PatientDetails" */ "../components/patients/PatientDetails.vue");
const ResourceList = () => import(/* webpackChunkName: "ResourceList" */ "../components/shared/resourceList.vue");
const Register = () => import("../components/register/index.vue");
const Portal = () => import("../components/portal/index.vue");
const PortalProfile = () => import("../components/portal/profile.vue");
const Organization = () => import("../components/settings/Organization.vue");
const Customize = () => import("../components/instances/Customize.vue");
const Landing = () => import(/* webpackChunkName: "Landing" */ "../components/instances/Landing.vue");
const Instances = () => import("../components/instances/InstanceList.vue");
const forms = () => import("../components/forms/index.vue");
const FormLanding = () => import("../components/forms/FormLanding.vue");
const editForms = () => import("../components/forms/edit.vue");
const messaging = () => import(/* webpackChunkName: "Messaging" */ "../components/messaging/MessagingList.vue");
const countries = () => import("../components/settings/countries.vue");
const Plans = () => import("../components/settings/Plans.vue");
const PlanDetails = () => import("../components/settings/PlanDetails.vue");
const messagingSettings = () => import("../components/settings/messaging.vue");
const integrations = () => import("../components/integrations/IntegrationList.vue");
const integrationDetails = () => import("../components/integrations/IntegrationDetails.vue");
const debug = () => import("../components/settings/debug.vue");
const board = () => import(/* webpackChunkName: "Board" */ "../components/board/Board.vue");
const tos = () => import("../components/portal/tos.vue");
const ScheduleList = () => import("../components/schedule/ScheduleList.vue");
const users = () => import("../components/users/UserList.vue");
const UserDetail = () => import("../components/users/UserDetail.vue");
const providers = () => import("../components/providers/index.vue");
const providerDetail = () => import("../components/providers/ProviderDetails.vue");
const Automations = () => import("../components/automations/Automations.vue");
const AutomationList = () => import("../components/automations/AutomationList.vue");
const AutomationLogs = () => import("../components/automations/AutomationLogs.vue");
const AutomationDetails = () => import("../components/automations/AutomationDetails.vue");
const Imports = () => import("../components/import/ImportList.vue");
const ImportDetails = () => import("../components/import/ImportDetails.vue");
const template = () => import("../components/settings/messageTemplate.vue");
const billing = () => import("../components/portal/billing.vue");
const PatientPortal = () => import("../components/portal/Patient.vue");
const NotFound = () => import("../components/shared/notfound.vue");
const SegmentDetail = () => import("../pages/Campaigns/SegmentDetail.vue");
const FtpUserDetails = () => import("../pages/FtpUserDetails.vue");
const FolderConfigDetails = () => import("../pages/FolderConfigDetails.vue");
const TaskEdit = () => import("../components/tasks/TaskEdit.vue");
const IdentityDetails = () => import("../components/settings/IdentityDetails.vue");
const templateEditor = () => import(/* webpackChunkName: "templateEditor" */ "../components/settings/messageTemplate.vue");

import { getUser, getCachedUser } from "../utils/auth-helper.js";
import { toProperCase } from "../utils/helpers.js";

const validateUser = async (to, from, next) => {
  if (to.fullPath !== from.fullPath) {
    try {
      let user = await getUser();
      if (user) {
        if (Array.isArray(to.meta.roles) && user.groups?.some(role => to.meta.roles.includes(role))) {
          next();
        } else if (to.meta.type === "patient") {
          next();
        }
        else {
          next(false); // Redirect to default route
        }
      } else {
        next();
      }
    } catch (error) {
      // Handle error here
      next(false);
      throw error;
    }
  } else {
    next(false);
  }
};

const allAdminRoles = ["Orbital-Platform-Admin", "Orbital-Organization-Admin", "Orbital-Instance-Admin"];

const checkForUser = async (to, _from, next) => {
  if (to?.query?.preview) next();
  let user = getCachedUser();
  if (user) {
    user = await getUser();

    if (user?.groups) {
      router.push({ name: "Home" });
      next(false);
    } else if (user?.patientID || user?.patients?.[0]) {
      let id = user?.patientID || user?.patients?.[0];
      router.push("portal/" + id);
      next(false);
    } else {
      next();
    }
  } else {
    next();
  }
};

const checkRouteFeature = async (to, _from, next) => {
  const features = inject('features');
  const feature = to.meta.feature;
  if (feature && features && !features[feature]) {
    // next({ name: 'NotFound' });
    router.push("/userLogin");
    next(false);
  } else {
    next();
  }
};

const router = new VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes: [
    {
      path: "/userLogin",
      name: "UserLogin",
      component: UserLogin,
      beforeEnter: checkForUser,
      meta: { type: "patient" },
    },
    {
      path: "/portal/:id?",
      name: "Portal",
      component: Portal,
      beforeEnter: validateUser,
      meta: { PortalDrawer: true, type: "patient" },
    },
    {
      path: "/home",
      name: "Home",
      component: () => import("../components/home/home.vue"),
      beforeEnter: validateUser,
      meta: {
        drawer: true,
        type: "admin",
        roles: allAdminRoles,
      },
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      beforeEnter: validateUser,
      meta: {
        drawer: true,
        type: "admin",
        roles: allAdminRoles,
      },
    },
    {
      path: "/patients",
      name: "Patients",
      component: Patients,
      beforeEnter: validateUser,
      meta: {
        drawer: true,
        type: "admin",
        roles: allAdminRoles,
      },
    },
    {
      path: "/patients/:id",
      name: "Patient Details",
      component: PatientDetails,
      beforeEnter: validateUser,
      meta: {
        drawer: true,
        type: "admin",
        roles: allAdminRoles,
      },
      children: [{ path: ":resourceType", name: "Resources", component: ResourceList, meta: { drawer: true, type: "admin" } }],
    },
    {
      path: "/organization",
      name: "Organization",
      component: Organization,
      beforeEnter: validateUser,
      meta: {
        drawer: true,
        title: "Organization",
        type: "admin",
        roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
      },
    },
    {
      path: "/instances",
      name: "Instances",
      component: Instances,
      beforeEnter: validateUser,
      meta: {
        drawer: true,
        title: "Instances",
        type: "admin",
        roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
      },
    },
    {
      path: "/instances/:id",
      name: "Customize",
      component: Customize,
      beforeEnter: validateUser,
      meta: {
        drawer: true,
        type: "admin",
        roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
      },
    },
    {
      path: "/",
      name: "Landing",
      component: Landing,
      beforeEnter: checkRouteFeature,
      meta: { type: "patient", feature: "landing" },
    },
    {
      path: "/forms",
      name: "Forms",
      component: forms,
      beforeEnter: validateUser,
      meta: {
        drawer: true,
        type: "admin",
        roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
      },
    },
    {
      path: "/forms/:id",
      name: "Edit Form",
      component: editForms,
      beforeEnter: validateUser,
      meta: {
        drawer: true,
        type: "admin",
        roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
      },
    },
    {
      path: "/forms/:id/landing",
      name: "Form Landing",
      component: FormLanding,
      // beforeEnter: validateUser,
      meta: {
        type: "patient",
      },
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      meta: { appBar: false, type: "patient" },
    },
    {
      path: "/tos",
      name: "Terms of Service",
      component: tos,
      meta: { type: "admin" },
    },
    {
      path: "/plans",
      name: "Plans",
      beforeEnter: validateUser,
      meta: { drawer: true, type: "admin", roles: ["Orbital-Platform-Admin"] },
      children: [
        { path: "", name: "Plan List", component: Plans, meta: { drawer: true, type: "admin" } },
        { path: ":id", name: "Plan Details", component: PlanDetails, meta: { drawer: true, type: "admin" } },
      ],
    },
    {
      path: "/integrations",
      name: "Integrations",
      component: integrations,
      beforeEnter: validateUser,
      meta: { drawer: true, type: "admin", roles: ["Orbital-Platform-Admin"] },
    },
    {
      path: "/integrations/:id",
      name: "Integration Details",
      component: integrationDetails,
      beforeEnter: validateUser,
      meta: {
        drawer: true,
        type: "admin",
        roles: ["Orbital-Platform-Admin"],
      },
    },
    {
      path: "/countries",
      name: "Countries",
      component: countries,
      beforeEnter: validateUser,
      meta: { drawer: true, type: "admin", roles: ["Orbital-Platform-Admin"] },
    },
    {
      path: "/board/:id?",
      name: "Board",
      component: board,
      beforeEnter: validateUser,
      meta: {
        drawer: true,
        type: "admin",
        roles: allAdminRoles,
      },
    },
    {
      path: "/portal/:id?/profile",
      name: "Portal Profile",
      component: PortalProfile,
      beforeEnter: validateUser,
      meta: { PortalDrawer: true, type: "patient" },
    },
    {
      path: "/portal/:id?/billing",
      name: "Billing",
      component: billing,
      beforeEnter: validateUser,
      meta: { PortalDrawer: true, type: "patient" },
    },
    {
      path: "/portal/:id?/patient",
      name: "Patient Portal",
      component: PatientPortal,
      beforeEnter: validateUser,
      meta: { PortalDrawer: true, type: "patient" },
    },
    {
      path: "/schedule",
      name: "Schedule List",
      component: ScheduleList,
      beforeEnter: validateUser,
      meta: {
        drawer: true,
        type: "admin",
        roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
      },
    },
    {
      path: "/users",
      name: "Users",
      component: users,
      beforeEnter: validateUser,
      meta: {
        drawer: true,
        type: "admin",
        roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
      },
    },
    {
      path: "/users/:id",
      name: "User Details",
      component: UserDetail,
      beforeEnter: validateUser,
      meta: {
        drawer: true,
        type: "admin",
        roles: allAdminRoles,
      },
    },
    {
      path: "/providers",
      name: "Providers",
      component: providers,
      beforeEnter: validateUser,
      meta: {
        drawer: true,
        type: "admin",
        roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
      },
    },
    {
      path: "/providers/:id",
      name: "Provider Details",
      component: providerDetail,
      beforeEnter: validateUser,
      meta: {
        drawer: true,
        type: "admin",
        roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
      },
    },
    {
      path: "/debug",
      name: "Debug",
      component: debug,
      beforeEnter: validateUser,
      meta: { drawer: true, type: "admin", roles: ["Orbital-Platform-Admin"] },
    },
    {
      path: "/messaging",
      name: "Messaging",
      component: messaging,
      beforeEnter: validateUser,
      meta: { drawer: true, type: "admin", roles: allAdminRoles },
    },
    {
      path: "/settings/message-templates",
      name: "Messaging Settings",
      component: messagingSettings,
      beforeEnter: validateUser,
      meta: { drawer: true, type: "admin", roles: ["Orbital-Platform-Admin"] },
    },
    {
      path: "/settings/message-templates/:id",
      name: "Message Templates",
      component: template,
      beforeEnter: validateUser,
      meta: {
        drawer: true,
        type: "admin",
        roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
        title: "Message Templates",
      },
    },
    {
      path: "/settings/identities",
      name: "Messaging Identity",
      children: [
        { path: ":id", name: "Identity Details", component: IdentityDetails, meta: { drawer: true, type: "admin" } },
        { path: "", name: "Identities", component: () => import(/* webpackChunkName: "identities" */ "../components/settings/Identities.vue"), meta: { drawer: true, type: "admin" } },
      ],
      beforeEnter: validateUser,
      meta: {
        drawer: true,
        type: "admin",
        roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
        title: "Identities",
      },
    },
    {
      path: "/settings/automations",
      component: Automations,
      beforeEnter: validateUser,
      children: [
        { path: ":id", name: "Automation Details", component: AutomationDetails, meta: { drawer: true, type: "admin" } },
        { path: "", name: "Automations", component: AutomationList, meta: { drawer: true, type: "admin", showNav: true } },
        { path: ":id/logs", name: "Automation Logs", component: AutomationLogs, meta: { drawer: true, type: "admin", showNav: true } },
      ],
      redirect: { name: "Automations" },
      meta: { drawer: true, type: "admin", roles: ["Orbital-Platform-Admin"] },
    },
    {
      path: "/settings/custom-fields",
      name: "Custom Fields",
      component: () => import("../pages/settings/CustomFields.vue"),
      beforeEnter: validateUser,
      meta: { drawer: true, type: "admin", roles: ["Orbital-Platform-Admin"] },
    },
    {
      path: "/settings/actions",
      name: "Actions",
      component: () => import(/* webpackChunkName: "Actions" */ "../pages/Actions.vue"),
      beforeEnter: validateUser,
      meta: { drawer: true, type: "admin", roles: ["Orbital-Platform-Admin"] },
    },
    {
      path: "/settings/task-types",
      name: "Task Types",
      component: () => import(/* webpackChunkName: "Actions" */ "../pages/tasks/Settings.vue"),
      beforeEnter: validateUser,
      meta: { drawer: true, type: "admin", roles: ["Orbital-Platform-Admin"] },
    },
    {
      path: "/settings/roles",
      component: Automations,
      beforeEnter: validateUser,
      children: [
        { path: ":id", name: "Role Details", component: () => import(/* webpackChunkName: "roles" */ "../pages/settings/RoleDetail.vue"), meta: { drawer: true, type: "admin" } },
        { path: "", name: "Roles", component: () => import(/* webpackChunkName: "roles" */ "../pages/settings/Roles.vue"), meta: { drawer: true, type: "admin", showNav: true } },
      ],
      redirect: { name: "Automations" },
      meta: { drawer: true, type: "admin", roles: ["Orbital-Platform-Admin"] },
    },
    {
      path: "/imports",
      name: "Imports",
      component: Imports,
      beforeEnter: validateUser,
      meta: { drawer: true, type: "admin", roles: ["Orbital-Platform-Admin"] },
    },
    {
      path: "/imports/:id",
      name: "Import Details",
      component: ImportDetails,
      beforeEnter: validateUser,
      meta: { drawer: true, type: "admin", roles: ["Orbital-Platform-Admin"] },
    },
    {
      path: "/campaigns/manage",
      name: "Campaigns",
      component: () => import(/* webpackChunkName: "CampaignList" */ "../pages/Campaigns/Campaigns.vue"),
      beforeEnter: validateUser,
      meta: { drawer: true, type: "admin", roles: ["Orbital-Platform-Admin"] },
    },
    {
      path: "/campaigns/manage/:id",
      name: "Campaign Details",
      component: () => import(/* webpackChunkName: "CampaignDetails" */ "../pages/Campaigns/CampaignDetails.vue"),
      beforeEnter: validateUser,
      meta: { drawer: true, type: "admin", roles: ["Orbital-Platform-Admin"] },
    },
    {
      path: "/campaigns/templates/",
      name: "Campaign Templates",
      children: [
        { path: ":id", name: "Template Edit", component: templateEditor, meta: { drawer: true, type: "admin" } },
        { path: "", name: "Templates", component: () => import(/* webpackChunkName: "campaigntemplates" */ "../pages/Campaigns/Templates.vue"), meta: { drawer: true, type: "admin" } },
      ],
      beforeEnter: validateUser,
      meta: { drawer: true, type: "admin", roles: ["Orbital-Platform-Admin"] },
    },
    {
      path: "/campaigns/segments/",
      name: "Segment Details",
      children: [
        { path: ":id", name: "Segment Edit", component: SegmentDetail, meta: { drawer: true, type: "admin" } },
        { path: "", name: "Segments", component: () => import(/* webpackChunkName: "segments" */ "../pages/Campaigns/Segments.vue"), meta: { drawer: true, type: "admin" } },
      ],
      beforeEnter: validateUser,
      meta: {
        drawer: true,
        type: "admin",
        roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
      },
    },
    {
      path: "/campaigns/mapping/",
      name: "Mapping",
      children: [
        { path: "attributes", name: "Attributes", component: () => import(/* webpackChunkName: "segments" */ "../pages/Campaigns/Attributes.vue"), meta: { drawer: true, type: "admin" } },
        { path: "metrics", name: "Metrics", component: () => import(/* webpackChunkName: "segments" */ "../pages/Campaigns/Segments.vue"), meta: { drawer: true, type: "admin" } },
      ],
      beforeEnter: validateUser,
      meta: {
        drawer: true,
        type: "admin",
        roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
      },
    },
    {
      path: "/campaigns/activity",
      name: "Campaign Activity",
      component: () => import(/* webpackChunkName: "CampaignActivity" */ "../pages/Campaigns/Activity.vue"),
      beforeEnter: validateUser,
      meta: { drawer: true, type: "admin", roles: ["Orbital-Platform-Admin"] },
    },
    {
      path: "/tasks",
      name: "Tasks",
      beforeEnter: validateUser,
      meta: { drawer: true, type: "admin", roles: ["Orbital-Platform-Admin"] },
      children: [
        { path: "", name: "Task List", component: () => import(/* webpackChunkName: "TaskList" */ "../pages/tasks/index.vue"), meta: { drawer: true, type: "admin" } },
        { path: ":id", name: "Task Edit", component: TaskEdit, meta: { drawer: true, type: "admin" } },
      ],
    },
    {
      path: "/:catchAll(.*)",
      name: "NotFound",
      component: NotFound,
    },
    {
      path: "/ftp-users/:id",
      name: "FTP User",
      component: FtpUserDetails,
      beforeEnter: validateUser,
      meta: {
        drawer: true,
        type: "admin",
        roles: ["Orbital-Platform-Admin"],
      },
    },
    {
      path: "/folder-configs/:id",
      name: "Folder Config",
      component: FolderConfigDetails,
      beforeEnter: validateUser,
      meta: {
        drawer: true,
        type: "admin",
        roles: ["Orbital-Platform-Admin"],
      },
    },
    {
      path: "/notifications",
      name: "Notifications",
      component: () => import(/* webpackChunkName: "Notifications" */ "../pages/Notifications.vue"),
      beforeEnter: validateUser,
      meta: { drawer: true, type: "admin", roles: allAdminRoles },
    },
    {
      path: "/signup",
      name: "Sign Up",
      component: () => import(/* webpackChunkName: "Signup" */ "../pages/Signup.vue"),
    },
    {
      name: 'Unsubscribe',
      path: '/unsubscribe/',
      component: () => import(/* webpackChunkName: "Unsubscribe" */ "../pages/Unsubscribe.vue"),
    },
    {
      path: '/organizations',
      name: 'Organizations',
      meta: { drawer: true, type: "admin", roles: ["Orbital-Platform-Admin"] },
      beforeEnter: validateUser,
      component: () => import(/* webpackChunkName: "OrganizationsLayout" */ "../pages/organizations/OrganizationsLayout.vue"),
      children: [
        { path: "", name: "OrganizationsList", component: () => import(/* webpackChunkName: "Organizations" */ "../pages/organizations/Organizations.vue"), meta: { drawer: true, type: "admin", roles: ["Orbital-Platform-Admin"] } },
        { path: ":id", name: "Organization Edit", component: () => import(/* webpackChunkName: "Organizations" */ "../pages/organizations/Organization.vue"), meta: { drawer: true, type: "admin", roles: ["Orbital-Platform-Admin"] } },
      ],
    }
  ],
});

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message) || /NavigationDuplicated./i.test(error.message)) {
    console.log("Reloading page");
    window.location.reload();
  }
});
router.beforeEach((to, _from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else if (to.meta.drawer) {
    document.title = toProperCase(to.name);
  }
  next();
});

export default router;
